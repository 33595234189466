import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/gus_up.css'
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import LoaderComponent from "../../components/Loader";
import useTranslation from "../../hooks/useTranslation";
import GreenNfs from "../../components/icons/GreenNfs";
import TonCircleIcon from "../../components/icons/TonCircleIcon";
import useP2POrders from "../../hooks/useP2POrders";
import PayTypeModal from "../../components/PayTypeModal";
import P2pPayTypeModal from "../../components/P2pPayTypeModal";


function P2PSalePage () {

    const [searchParams] = useSearchParams();

    // Получаем значение параметра order_id из URL
    const orderId = searchParams.get('order_id');

    const { language, translation, handleLanguageChange } = useTranslation(); // Используем хук
    const { user, webApp, triggerVibration } = useTelegram();
    const {nfsBalance, userData, loading, error, fetchUserData } = useUserData();


    const {p2pMainData, allOrders,  loadingAllOrders, errorAllOrders, fetchGetAllOrders,
        transactionData, loadingTransactionData, errorTransactionData, fetchGetTransaction,
        orderInfo, loadingOrderInfo, errorOrderInfo, fetchGetOrderInfo,
        unblockData, loadingUnblockData, errorUnblockData, fetchUnblockTransaction


    } = useP2POrders();

    const [convertedNfs, setConvertedNfs] = useState('0');

    const [showPayTypeModal, setShowPayTypeModal] = useState(false);



    const navigate = useNavigate();

    const isMounted = useRef(false);

    useEffect(()=>{
        if (unblockData) {
            navigate('/p2p');
        }
    }, [unblockData])

    useEffect(()=>{
        if (transactionData) {
            console.log(transactionData, 'transactionData')
            setShowPayTypeModal(true)
        }
    }, [transactionData])

     useEffect(()=>{
        if (!isMounted.current) {
            console.log(orderId, 'orderId')
            fetchGetOrderInfo(orderId)
            isMounted.current = true;
        }
    }, [orderId])

    return (
        <>

            {( loading || loadingTransactionData ) && (
                <LoaderComponent/>
            )}

            <div className="gus_up_page_wrapper sale-p2p-wrapper" id='gus_up_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>
                            <GazAmount userData={userData}/>


                        </div>

                    </div>
                    <Link
                        onClick={()=>{
                            triggerVibration('heavy')
                        }}
                        to={'/'}
                        className="general_page_item"
                    >
                        <div className="general_page_item_logo">
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </div>
                    </Link>

                    <div className="general_page_item">
                        <div className="general_page_item_top_child">

                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/balanceNfs'}
                                className="general_page_item_top_child_title_icon_wrapper"
                            >
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </Link>

                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>

                        </div>
                    </div>

                </div>

                <div className='gus_up_page_main_items_wrapper sale_p2p_page_main_items_wrapper'>

                    <div className="gus_up_page_main_item2 p2p_sell_page_main_item2">

                        <div className={'p2p_sale_main_green_title'}>
                            {translation?.buy}
                        </div>

                        <div className='gus_up_page_details_items_wrapper'>

                            <p className={'p2p-buy-label'}>Seller</p>
                            <div className={'current_minimum_price_bottom p2p-buy-label-wallet'}>
                                {orderInfo?.sellerTonAddress}
                            </div>

                            <div className='gus_up_page_details_item'>

                                <div className="gus_up_page_details_item_icon_title_wrapper p2p_sale_page_details_item_icon_title_wrapper">
                                    <div className="gus_up_page_details_item_icon">
                                        <GreenNfs/>
                                    </div>
                                    <p className="gus_up_page_details_item_title p2p_sale_page_details_item_title">
                                        <span>{translation?.you_have_coins}: </span>
                                        <span>{p2pMainData?.p2p_available_nfs_amount} NFS</span>
                                    </p>
                                </div>

                                {/*NFS*/}
                                <div className="gus_up_page_details_item_info_box">
                                    <p className="gus_up_page_details_item_info2">{orderInfo?.nfsAmount}</p>
                                </div>

                                <p className="gus_up_page_details_item_title p2p_sale_page_details_item_title bottom">
                                    <span>{translation?.buy_commission}: </span>
                                    <span>{orderInfo?.commission}%</span>
                                </p>

                            </div>

                            {/*Price*/}
                            <div className='gus_up_page_details_item p2p_buy_label_main_wrapper'>
                                <div className="gus_up_page_details_item_icon_title_wrapper p2p_buy_label">
                                    <TonCircleIcon/> <span>{translation?.price}</span>
                                </div>
                                <div className="gus_up_page_details_item_info_box">
                                    <p className="gus_up_page_details_item_info2 blue-color">{orderInfo?.tonAmount}</p>
                                </div>
                            </div>

                            {/*Gas Fee*/}
                            <div className='gus_up_page_details_item p2p_buy_label_main_wrapper'>
                                <div className="gus_up_page_details_item_icon_title_wrapper p2p_buy_label">
                                    <TonCircleIcon/> <span>{translation?.gaz_fee}</span>
                                </div>
                                <div className="gus_up_page_details_item_info_box">
                                    <p className="gus_up_page_details_item_info2 blue-color">{orderInfo?.commission}%</p>
                                </div>
                            </div>


                            {/*Gas Fee*/}
                            <div className='gus_up_page_details_item p2p_buy_label_main_wrapper'>
                                <div className="gus_up_page_details_item_icon_title_wrapper p2p_buy_label">
                                    <TonCircleIcon/> <span style={{fontSize:18}}>TON</span>
                                    <p>{translation?.total}</p>
                                </div>
                                <div className="gus_up_page_details_item_info_box">
                                    <p className="gus_up_page_details_item_info2 blue-color">{orderInfo?.fullTonAmount}</p>
                                </div>
                            </div>


                        </div>



                    </div>
                </div>

                <div style={{padding: 10}}>
                    <div
                        onClick={() => {
                            triggerVibration('heavy')

                            fetchGetTransaction(orderId);
                        }}

                        className='custom_btn custom_btn_green'
                    >
                        {translation?.buy}
                        {/*<img src={require('../../assets/images/balance_btn_img.png')} alt=""/>*/}
                    </div>

                    <div
                        onClick={() => {
                            triggerVibration('heavy')

                            if (window.confirm('Вы уверены, что хотите выйти?')) {
                                fetchUnblockTransaction(orderId)
                            } else {
                                // Действия при отмене
                                console.log('Удаление отменено');
                            }
                        }}
                        // to={'/p2p'}
                        className='custom_btn'
                    >
                        {translation?.go_back}
                        {/*<img src={require('../../assets/images/balance_btn_img.png')} alt=""/>*/}
                    </div>

                </div>
            </div>

            {showPayTypeModal &&
                <P2pPayTypeModal
                    data={transactionData}
                    isActive={true}
                    onClose={() => {
                        setShowPayTypeModal(false)
                    }}
                />
            }


        </>

    );
}

export default P2PSalePage;

