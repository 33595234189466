import React, {useEffect, useState,} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/balance_nfs.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {CloseBalanceIcon} from "../../components/icons/CloseBalanceIcon";
import {BalanceMainIcon} from "../../components/icons/BalanceMainIcon";
import {BalancePetrol} from "../../components/icons/BalancePetrol";
import  BuyPackageModal from "../../components/BuyPackageModal";
import useNFSPackages from "../../hooks/useNFSPackages";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import LoaderComponent from "../../components/Loader";
import {InputNfsIcon2} from "../../components/icons/InputNfsIcon2";

import PayTypeModal from "../../components/PayTypeModal";
import useTranslation from "../../hooks/useTranslation";
import DeleteIcon from "../../components/icons/DeleteIcon";
import useP2POrders from "../../hooks/useP2POrders";
import DeleteP2POrderModal from "../../components/DeleteP2POrderModal";

function BalanceNfsPage () {
    const { user, triggerVibration } = useTelegram();
    const { language, translation, handleLanguageChange } = useTranslation(); // Используем хук

    const {nfsBalance, userData, loading, error } = useUserData();

    const {p2pMainData, allOrders,  loadingAllOrders, errorAllOrders, fetchGetAllOrders,
        p2PHistory, loadingP2PHistory, errorP2PHistory, fetchP2PHistory,
    } = useP2POrders();


    const [showDeleteOrderModal, setShowDeleteOrderModal] = useState(false);
    const [showDeleteOrderId, setShowDeleteOrderId] = useState(null);



    const navigate = useNavigate();

    useEffect(()=>{
        fetchP2PHistory();
    }, [])


    return (
        <>
            {( loading || loadingP2PHistory  ) && (
                <LoaderComponent/>
            )}
            <div className="balance_nfs_page_wrapper p2pbg" id='balance_nfs_page' style={{display: "flex", flexDirection: 'column'}}>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>
                            <GazAmount userData={userData}/>


                        </div>

                    </div>
                    <div className="general_page_item">
                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/'}
                            className="general_page_item_logo"

                        >
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </Link>


                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <div className="general_page_item_top_child_title_icon_wrapper">
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </div>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="balance_main_wrapper" style={{display: 'flex', flex: 1, flexDirection: 'column'}}>


                    <div className={'p2p-wrapper'} style={{flex: 1}}>
                        <p className={'history-main-title'}>
                            история транзакций
                        </p>
                        <div className={'p2p-myorders-wrapper'} style={{position: 'relative'}}>


                            {p2PHistory?.history && p2PHistory?.history.length == 0 &&
                                <p className={'empty-history'}>
                                    Здесь будет история <br/> ваших транзакций
                                </p>
                            }

                            {p2PHistory?.history && p2PHistory?.history.length > 0 && p2PHistory?.history.map((item, index) => {
                                return (
                                    <div className={'history-item'}>
                                        <span>Купил это и это</span>
                                        <span>{item?.buyerAddress}</span>
                                    </div>
                                )
                            })}


                            {/*<div className={'history-item'}>*/}
                            {/*    <span>Купил это и это</span>*/}
                            {/*    <span>39WmtWpdks3Q897qFYA73KBuUMSAuZhtU2</span>*/}
                            {/*</div>*/}
                            {/*<div className={'history-item'}>*/}
                            {/*    <span>Купил это и это</span>*/}
                            {/*    <span>39WmtWpdks3Q897qFYA73KBuUMSAuZhtU2</span>*/}
                            {/*</div>*/}
                        </div>

                    </div>

                    <div style={{padding: 10}}>
                        <div

                            onClick={() => {
                                triggerVibration('heavy')
                                window.location.href = 'https://t.me/nfs_ton_support'
                            }}
                            className='custom_btn custom_btn_grey'
                        >
                            {translation?.support}
                            {/*<img src={require('../../assets/images/balance_btn_img.png')} alt=""/>*/}
                        </div>

                        <Link
                            onClick={() => {
                                triggerVibration('heavy')
                            }}
                            to={'/p2p'}
                            className='custom_btn'
                        >
                            {translation?.go_back}
                            {/*<img src={require('../../assets/images/balance_btn_img.png')} alt=""/>*/}
                        </Link>

                    </div>
                </div>


            </div>
        </>

    );
}

export default BalanceNfsPage;

