import React, {useEffect, useState,} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTelegram } from '../../TelegramContext';
import '../../assets/css/balance_nfs.css'
import {PetrolRedIcon} from "../../components/icons/PetrolRedIcon";
import {PetrolWhiteIcon} from "../../components/icons/PetrolWhiteIcon";
import {SettingsIcon} from "../../components/icons/SettingsIcon";
import {ReplenishIcon} from "../../components/icons/ReplenishIcon";
import {MoneyIcon} from "../../components/icons/MoneyIcon";
import {WalletIcon} from "../../components/icons/WalletIcon";
import {LogoIcon} from "../../components/icons/LogoIcon";
import {CloseBalanceIcon} from "../../components/icons/CloseBalanceIcon";
import {BalanceMainIcon} from "../../components/icons/BalanceMainIcon";
import {BalancePetrol} from "../../components/icons/BalancePetrol";
import  BuyPackageModal from "../../components/BuyPackageModal";
import useNFSPackages from "../../hooks/useNFSPackages";
import useUserData from "../../hooks/useUserData";
import GazAmount from "../../components/GazAmount";
import LoaderComponent from "../../components/Loader";
import {InputNfsIcon2} from "../../components/icons/InputNfsIcon2";

import PayTypeModal from "../../components/PayTypeModal";
import useTranslation from "../../hooks/useTranslation";
import DeleteIcon from "../../components/icons/DeleteIcon";
import useP2POrders from "../../hooks/useP2POrders";
import DeleteP2POrderModal from "../../components/DeleteP2POrderModal";

function BalanceNfsPage () {
    const { user, triggerVibration } = useTelegram();
    const { language, translation, handleLanguageChange } = useTranslation(); // Используем хук

    const {nfsBalance, userData, loading, error } = useUserData();

    const {p2pMainData, allOrders,  loadingAllOrders, errorAllOrders, fetchGetAllOrders,
        myOrders, loadingMyOrders, errorMyOrders, fetchGetMyOrders,
        deleteMyOrder, loadingDeleteMyOrder, errorDeleteMyOrder, fetchDeleteOrder,
        transactionData, loadingTransactionData, errorTransactionData, fetchGetTransaction,
    } = useP2POrders();

    const [p2pFilterActiveItem, setP2pFilterActiveItem] = useState('all'); // all, mos_sdelki, prodazhi
    const [isActivefilterTop, setIsActivefilterTop] = useState(false);

    const [showDeleteOrderModal, setShowDeleteOrderModal] = useState(false);
    const [showDeleteOrderId, setShowDeleteOrderId] = useState(null);



    useEffect(() => {
        if (p2pFilterActiveItem == 'mos_sdelki') {
            fetchGetMyOrders(isActivefilterTop ? 'asc' : 'desc');
        } else {
            fetchGetAllOrders(isActivefilterTop ? 'asc' : 'desc')
        }
    }, [isActivefilterTop]);



    useEffect(() => {
        if (deleteMyOrder) {
            fetchGetMyOrders(isActivefilterTop ? 'asc' : 'desc');
        }
    }, [deleteMyOrder]);

    const navigate = useNavigate();



    return (
        <>
            {( loading || loadingDeleteMyOrder || loadingMyOrders || loadingAllOrders ) && (
                <LoaderComponent/>
            )}
            <div className="balance_nfs_page_wrapper p2pbg" id='balance_nfs_page'>
                <div className='general_page_header'>
                    <div className='general_page_item'>
                        <div className="general_page_item_top_child">
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/settings'}
                                className="general_page_item_top_child_icon"
                                id='general_page_item_top_child_icon1'
                            >
                                <SettingsIcon/>
                            </Link>
                            <GazAmount userData={userData}/>


                        </div>

                    </div>
                    <div className="general_page_item">
                        <Link
                            onClick={()=>{
                                triggerVibration('heavy')
                            }}
                            to={'/'}
                            className="general_page_item_logo"

                        >
                            <div className="general_page_item_logo_box">
                                <LogoIcon/>
                            </div>

                            <div className="general_page_item_logo_img">
                                <img src={require('../../assets/images/logo.png')} alt=""/>
                            </div>
                        </Link>


                    </div>
                    <div className="general_page_item">
                        <div className="general_page_item_top_child">
                            <div className="general_page_item_top_child_title_icon_wrapper">
                                <MoneyIcon/>
                                <p className='general_page_item_top_child_title'>{nfsBalance}</p>
                            </div>
                            <Link
                                onClick={()=>{
                                    triggerVibration('heavy')
                                }}
                                to={'/wallet'}
                                className='general_page_item_top_child_icon'
                                id='general_page_item_top_child_icon2'
                            >
                                <WalletIcon/>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="balance_main_wrapper">


                    <div className={'p2p-wrapper'}>
                        <div className={'p2p-top-info'}>

                            <div className={'p2p-top-info-title'}>
                                p2p
                            </div>

                            <div className={'p2p-top-info-item green'}>
                                <div className={'p2p-top-info-item-left'}>
                                    {translation?.airdrop_balance}:
                                </div>



                                <div className={'p2p-top-info-item-right'}>
                                    <span>{p2pMainData?.airdrop_nfs_amount}</span>
                                    <img src={require('../../assets/p2p/greenNfs.png')} alt=""/>
                                </div>

                            </div>
                            <div className={'p2p-top-info-item red'}>
                                <div className={'p2p-top-info-item-left'}>
                                    <div
                                        className={'p2p-top-info-item-left-info-img-wrapper'}
                                        onClick={()=>{

                                        }}
                                    >
                                        <img src={require('../../assets/p2p/info.png')} alt=""/>
                                    </div>
                                    {translation?.available_for_withdrawal}:
                                </div>

                                <div className={'p2p-top-info-item-right'}>
                                    <span>{p2pMainData?.p2p_available_nfs_amount}</span>
                                    <img src={require('../../assets/p2p/redNfs.png')} alt=""/>
                                </div>

                            </div>
                            <div className={'p2p-top-info-item blue'}>
                                <div className={'p2p-top-info-item-left'}>
                                    {translation?.p2p_balance} TON:
                                </div>

                                <div className={'p2p-top-info-item-right'}>
                                    <span>{p2pMainData?.ton_amount}</span>
                                    <img src={require('../../assets/p2p/ton.png')} alt=""/>
                                </div>

                            </div>
                        </div>


                        <div className={'p2p-filter-main-wrapper'}>

                            <div className={'p2p-filter-wrapper'}>

                                <div onClick={() => {
                                    fetchGetAllOrders(isActivefilterTop ? 'asc' : 'desc')
                                    setP2pFilterActiveItem('all')
                                }} className={`p2p-filter-item ${p2pFilterActiveItem == 'all' && 'active'}`}>
                                    {translation?.all}
                                </div>

                                <div onClick={() => {
                                    fetchGetMyOrders(isActivefilterTop ? 'asc' : 'desc')
                                    setP2pFilterActiveItem('mos_sdelki');
                                }} className={`p2p-filter-item ${p2pFilterActiveItem == 'mos_sdelki' && 'active'}`}>
                                    {translation?.my_trades}
                                </div>

                            </div>


                            <Link to={'/P2PSalePage'}  className={'p2p-cart-btn'}>
                                <img src={require('../../assets/p2p/cart.png')} alt=""/>
                            </Link>

                            <button className={'p2p-filter-btn'} onClick={() => {
                                setIsActivefilterTop(prevState => !prevState)
                            }}>
                                <img className={`${isActivefilterTop ? 'reverse' : ""}`} src={require('../../assets/p2p/logo/sort.png')} alt=""/>
                            </button>


                            <Link to={'/P2PHistory'} className={'p2p-filter-btn'}>
                                <img src={require('../../assets/p2p/history.png')} alt=""/>
                            </Link>

                        </div>


                        {p2pFilterActiveItem == 'mos_sdelki' ?

                            <>
                                {!myOrders ?

                                    <div className={'p2p-myorders-wrapper empty'}>

                                        <span>Здесь будут ваши текущие <br/> актуальные ордеры</span>

                                    </div>

                                    :
                                    <div className={'p2p-myorders-wrapper'}>

                                        {myOrders.map((item, index) => {
                                            return (
                                                <div className={'p2p-myorders-item'}>

                                                    <div className={'p2p-myorders-item-left'}>
                                                        <span>Name</span>
                                                        <span>{item?.seller_ton_address}</span>
                                                    </div>

                                                    <div className={'p2p-myorders-item-right'}>

                                                        <div className={'p2p-myorders-item-right-left'}>

                                                            <div className={'p2p-myorders-item-right-top'}>
                                                                <span>{translation?.sale}</span>
                                                                <img src={require('../../assets/p2p/nfs2.png')} alt=""/>
                                                            </div>

                                                            <div className={'p2p-myorders-item-right-bottom'}>
                                                                <span>{item?.nfs_amount}</span>
                                                            </div>

                                                        </div>
                                                        <div className={'p2p-myorders-item-right-right'}>
                                                            <div className={'p2p-myorders-item-right-top'}>
                                                                <span>{translation?.profit}</span>
                                                                <img src={require('../../assets/p2p/ton2.png')} alt=""/>
                                                            </div>

                                                            <div
                                                                className={'p2p-myorders-item-right-bottom'}
                                                            >
                                                                <span>{item?.ton_amount}</span>
                                                            </div>
                                                        </div>

                                                        <div
                                                            onClick={()=>{
                                                                setShowDeleteOrderModal(true);
                                                                setShowDeleteOrderId(item?.order_id)
                                                            }}
                                                            className={'p2p-delete-btn'}
                                                        >

                                                            <DeleteIcon/>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}


                                    </div>

                                }

                            </>
                            :
                            <div className={'p2p-list-wrapper'}>
                                {!allOrders &&
                                    <div className={'p2p-myorders-wrapper empty'}>

                                        <span>Здесь будут текущие <br/> актуальные ордеры</span>

                                    </div>
                                }


                                {allOrders && allOrders.map((item, index) => {
                                    return (
                                        <div key={index} className="p2p-list-item">
                                            <div className="p2p-list-item-nfs">
                                                <img src={require('../../assets/p2p/logo/nfs.png')} alt=""/>
                                                <div className="p2p-list-item-value">
                                                    {item?.nfs_amount}
                                                </div>
                                            </div>

                                            <div className="p2p-list-item-ton">
                                                <img src={require('../../assets/p2p/logo/ton.png')} alt=""/>
                                                <div className="p2p-list-item-value">
                                                    {item?.ton_amount}
                                                </div>
                                            </div>

                                            <div className="p2p-list-item-info">
                                                <div className="p2p-list-item-info-item">
                                                    <span>{item?.ton_to_nfs}</span>
                                                    <span>TON/NFS</span>
                                                </div>

                                                <div className="p2p-list-item-info-item">
                                                    <span>Name</span>
                                                    <span>{item?.seller_ton_address}</span>
                                                </div>
                                            </div>

                                            <Link to={`/P2PBuyPage?order_id=${item?.order_id}`}>
                                                <button>
                                                    {translation?.buy}

                                                </button>
                                            </Link>


                                        </div>

                                    )
                                })}
                            </div>

                        }

                    </div>

                    <Link
                        onClick={() => {
                            triggerVibration('heavy')
                        }}
                        to={'/general'}
                        className='balance_btn'
                    >
                        {translation?.back_to_home}
                        {/*<img src={require('../../assets/images/balance_btn_img.png')} alt=""/>*/}
                    </Link>
                </div>

                <DeleteP2POrderModal
                    isActive={showDeleteOrderModal}
                    onClose={() => {
                        setShowDeleteOrderModal(false)
                    }}
                    deleteClose={() => {
                        if (showDeleteOrderId) {
                            setShowDeleteOrderModal(false)
                            fetchDeleteOrder(showDeleteOrderId);

                        }
                    }}
                />


                {/*{showPayTypeModal &&*/}
                {/*    <PayTypeModal*/}
                {/*        data={payData}*/}
                {/*        isActive={true}*/}
                {/*        onClose={() => {*/}
                {/*            setShowPayTypeModal(false)*/}
                {/*        }}*/}
                {/*    />*/}
                {/*}*/}
            </div>
        </>

    );
}

export default BalanceNfsPage;

